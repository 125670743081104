import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import colorSharp2 from "../assets/img/color-sharp2.png";
import logoStego from "../assets/img/logo_stego.png";
import logoGO from "../assets/img/jeudego.png";
import logoBot from "../assets/img/discord-bot.png";
import logoMusic from "../assets/img/music-logo.png";
import logoSerious from "../assets/img/logo-serious-game.png";
import logoPorfolio from "../assets/img/logo-portfolio.png";
import logobilletterie from "../assets/img/billet.png";
import logoParcoursup from "../assets/img/parcoursup.png";
import { useTranslation } from "react-i18next";

export const Projects = () => {
  const { t } = useTranslation();

  const projectsJava = [
    {
      title: <span>{t("trad.projects.java.project1.title")}</span>,
      description: <span>{t("trad.projects.java.project1.text")}</span>,
      imageUrl: logoStego,
      link: "https://github.com/MehdiBoussalem/Steganography-project",
    },
    {
      title: <span>{t("trad.projects.java.project2.title")}</span>,
      description: <span>{t("trad.projects.java.project2.text")}</span>,
      imageUrl: logoGO,
      link: "https://github.com/MehdiBoussalem/GO-GAME",
    },
  ];
  const projectsPython = [
    {
      title: <span>{t("trad.projects.python.project1.title")}</span>,
      description: <span>{t("trad.projects.python.project1.text")}</span>,
      imageUrl: logoBot,
      link: "https://github.com/MehdiBoussalem/my-personal-discord-bot",
    },
    {
      title: <span>{t("trad.projects.python.project2.title")}</span>,
      description: <span>{t("trad.projects.python.project2.text")}</span>,
      imageUrl: logoSerious,
      link: "",
    },
    {
      title: <span>{t("trad.projects.python.project3.title")}</span>,
      description: <span>{t("trad.projects.python.project3.text")}</span>,
      imageUrl: logoParcoursup,
      link: "",
    },
  ];
  const projectsWeb = [
    {
      title: <span>{t("trad.projects.web.project1.title")}</span>,
      description: <span>{t("trad.projects.web.project1.text")}</span>,
      imageUrl: logoMusic,
      link: "https://github.com/MehdiBoussalem/PHP-music-Project",
    },
    {
      title: <span>{t("trad.projects.web.project2.title")}</span>,
      description: <span>{t("trad.projects.web.project2.text")}</span>,
      imageUrl: logoPorfolio,
      link: "https://mehdiboussalem.com/",
    },
  ];
  const projectsBDD = [
    {
      title: <span>{t("trad.projects.bdd.project1.title")}</span>,
      description: <span>{t("trad.projects.bdd.project1.text")}</span>,
      imageUrl: logobilletterie,
      link: "",
    },
  ];
  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col>
            <h2>{t("trad.projects.title")}</h2>
            <p>{t("trad.projects.text")}</p>
            <Tab.Container id="project-tabs" defaultActiveKey="java">
              <Nav
                variant="pills"
                defaultActiveKey="/home"
                className="nav-pills mb-5 justify-content-center align-items-center"
                id="pills-tab"
              >
                <Nav.Item>
                  <Nav.Link eventKey="java">Java</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="python">Python</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="web">Web</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="bdd">BDD/Réseau</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content id="slideInUp">
                <Tab.Pane eventKey="java">
                  <Row>
                    {projectsJava.map((project, index) => {
                      return <ProjectCard key={index} {...project} />;
                    })}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="python">
                  {" "}
                  <Row>
                    {projectsPython.map((project, index) => {
                      return <ProjectCard key={index} {...project} />;
                    })}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="web">
                  {" "}
                  <Row>
                    {projectsWeb.map((project, index) => {
                      return <ProjectCard key={index} {...project} />;
                    })}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="bdd">
                  {" "}
                  <Row>
                    {projectsBDD.map((project, index) => {
                      return <ProjectCard key={index} {...project} />;
                    })}
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
