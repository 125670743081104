import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const Resume = () => {
  const { t } = useTranslation();

  return (
    <section className="resume" id="resume">
      <Container>
        <Row>
          <Col>
            <h2>{t("trad.resume.title")}</h2>
            <p>{t("trad.resume.text")}</p>
          </Col>
        </Row>
        <Row>
          <div className="resume-bx ">
            <Col sm={12} md={6}>
              <h3>{t("trad.resume.education")}</h3>

              <div className="resume-item">
                <h4>{t("trad.resume.school2.title")}</h4>
                <h5>2020 - 2024</h5>
                <span>{t("trad.resume.school2.location")}</span>
              </div>
              <div className="resume-item">
                <h4>{t("trad.resume.school1.title")}</h4>
                <h5>2017 - 2020</h5>
                <span>{t("trad.resume.school1.location")}</span>
              </div>
            </Col>
            <Col sm={12} md={6}>
              <h3>{t("trad.resume.experience")}</h3>
              <div className="resume-item">
                <h4>{t("trad.resume.aucune.title")}</h4>
                <span>{t("trad.resume.aucune.location")}</span>
              </div>
            </Col>
          </div>
        </Row>
      </Container>
    </section>
  );
};
